



























































import {
	Vue,
	Component
} from 'vue-property-decorator'
import {
	apiAppSettings,
	apiAppSettingsSet
} from '@/api/channel/app_store'
import {
	AppSettings_Req
} from '@/api/channel/app_store.d.ts'
@Component({
	components: {},
})
export default class AppStore extends Vue {
	/** S Data **/
	form: AppSettings_Req = {
		ios_download_url: '', // 苹果APP下载链接
		android_download_url: '', // 安卓APP下载链接
		download_title: '', // APP下载引导文案
		app_id: '', // 开放平台appid
		app_secret: '' // 开放平台appSecrets
	}

	// 表单验证
	formRules = {
		app_id: [{
			required: true,
			message: '必填项不能为空',
			trigger: 'blur'
		}],
		app_secret: [{
			required: true,
			message: '必填项不能为空',
			trigger: 'blur'
		}],
	}
	$refs!: {
		formRef: any
	}
	/** E Data **/

	// 获取APP设置
	getAppSettings() {
		apiAppSettings()
			.then((res: any) => {
				this.form = res
			})
			.catch(() => {
				console.log('数据请求失败!')
			})
	}

	// 修改APP设置
	putAppSettings() {
		this.$refs.formRef.validate((valid: boolean) => {
			// 预校验
			if (!valid) {
				return this.$message.error('请完善信息');
			}
			apiAppSettingsSet(this.form)
				.then((res: any) => {
					this.getAppSettings()
					// this.$message.success('保存成功!')
				})
				.catch(() => {
					// this.$message.error('保存失败!')
				})
		})
	}
	/** S Life Cycle **/
	created() {
		this.getAppSettings()
	}
	/** E Life Cycle **/
}
